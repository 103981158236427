.container {
    position: relative;
    height: 90vh;
    width: 70vh;
    padding: 20px 20px 100px;
    border: solid 1px #999;
    font-size: 50px;
    background-image: url("assets/inside.png");
    background-size: cover;
    overflow: visible;
    cursor: pointer;
    color: #222;
}

.cover {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 90vh;
    width: 70vh;
    border: solid 1px #999;
    z-index: 100;

    transform-style: preserve-3d;
    transform-origin: 0% 50%;
    backface-visibility: hidden;

    animation: open 1000ms ease;
    animation-fill-mode: forwards;

    animation-play-state: paused;

    :global(.__animating) & {
        animation-play-state: running;
    }

    &::before, &::after {
        display: block;
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
    }

    &::before {
        background-image: url("assets/cover.png");
        background-size: cover;
        z-index: 110;
    }

    &::after {
        background-image: url("assets/inside.png");
        background-size: cover;
        transform: rotateY(180deg);
        z-index: 90;
    }
}

@keyframes open {
    from {
        transform: perspective(4000px) rotateY(0deg);
    }
    to {
        transform: perspective(4000px) rotateY(-110deg);
    }
}
