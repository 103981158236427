@mixin animate-from() {
    transform: translateY(25px);
    opacity: 0;
}

@mixin animate-to() {
    transform: translateY(0px);
    opacity: 1;
}

.container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.word {
    display: inline-block;
    white-space: pre;
}

.letter {
    display: inline-block;
    white-space: pre;
    animation: animate 300ms ease;
    animation-fill-mode: forwards;

    @include animate-from();

    animation-play-state: paused;

    :global(.__animating) & {
        animation-play-state: running;
    }
}

@keyframes animate {
    from { 
        @include animate-from();
    }
    to { 
        @include animate-to();
    }
}
