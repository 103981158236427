body {
    background-color: #ffcfce;
    overflow: hidden;
}

.wrapper {
    display: flex;
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
}
